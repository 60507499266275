import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, Container, Row, Col, Button } from "reactstrap";

interface Props {
  itemName: string;
  itemType: string;
  itemId: string;
  isOpen: boolean;
  onSubmit: (id: string)=>void;
  onCancel: ()=>void;
}

export default class GenericDelete extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>
            Delete a {this.props.itemType}:
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <Container>
            <Row>
              <Col>
                <p>Do you wish to permanently delete '{this.props.itemName}' and all associated data? This cannot be undone.</p>
                <Button color="secondary" outline onClick={()=>this.props.onCancel()}>Cancel</Button>
                <Button color="danger" className="pull-right" onClick={()=>this._handleDelete()}>Delete</Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _handleDelete(){
    this.props.onSubmit(this.props.itemId);
    this.setState({name: ""});
  }
}

