import { Service, Game, Team, Player } from "../model/GameModel";
/**
 * Saves/loads game details from [browser storage]
 */
export class GameServiceLocalStorage implements Service {

  async listTeams(): Promise<Array<Team>> {
    const savedTeams = localStorage.getItem("teams") || "[]";
    const teams = JSON.parse(savedTeams) as Array<Team>;
    return teams;
  }

  async selectTeam(id?: string): Promise<Team|undefined> {
    if (id != null) {
      const savedTeams = await this.listTeams();
      for (const team of savedTeams) {
        if (team.teamId === id) {
          localStorage.setItem("selected-team", id);
          return team;
        }
      }
    } else {
      localStorage.removeItem("selected-team");
    }
  }

  async selectedTeam(): Promise<Team|undefined> {
    const id = localStorage.getItem("selected-team");
    if (!id) return;
    const savedTeams = await this.listTeams();
    for (const team of savedTeams) {
      if (team.teamId === id) {
        return team;
      }
    }
  }

  async upsertTeam(team: Team): Promise<Team> {
    team = clone(team);
    const teams = await this.listTeams();
    if (team.teamId == null) {
      let nextId = 1;
      while (true) {
        if (!teams.find(g => g.teamId === nextId + "")) {
          break;
        }
        nextId += 1;
      }  
      team.teamId = nextId+"";
      teams.push(team);
    } else {
      let found = false;
      for (let i=0; i<teams.length; i++) {
        if (teams[i].teamId === team.teamId) {
          teams[i] = team;
          found = true;
          break;
        }
      }
      if (!found) {
        throw new Error(`Team with id ${team.teamId} not found`);
      }
    }
    localStorage.setItem("teams", JSON.stringify(teams));
    return team;
  }

  async deleteTeam(id: string): Promise<void> {
    const savedTeams = await this.listTeams();
    const newTeams = [];
    for (const team of savedTeams) {
      if (team.teamId !== id) {
        newTeams.push(team);
      }
    }
    localStorage.setItem("teams", JSON.stringify(newTeams));
    const selectedTeam = await this.selectedTeam();
    if (selectedTeam && selectedTeam.teamId === id) {
      await this.selectTeam();
    }
    
    // todo: delete players; delete games; ...
  }

  async listAllPlayers(): Promise<Array<Player>> {
    const savedPlayers = localStorage.getItem("players") || "[]";
    const players = JSON.parse(savedPlayers) as Array<Player>;
    return players;

  }

  async listPlayers(teamId?: string): Promise<Array<Player>> {
    const allPlayers = await this.listAllPlayers();
    const teamPlayers = allPlayers.filter(p=>p.teamId === teamId);
    return teamPlayers;
  }

  async listAllGames(): Promise<Array<Game>> {
    const savedGames = localStorage.getItem("games") || "[]";
    const games = JSON.parse(savedGames) as Array<Game>;
    return games;
  }

  async listGames(teamId: string): Promise<Array<Game>> {
    const allGames = await this.listAllGames();
    return allGames.filter(g=>g.teamId === teamId);
  }

  async selectGame(id?: string): Promise<Game|undefined> {
    if (id != null) {
      const savedGames = await this.listAllGames();
      for (const game of savedGames) {
        if (game.gameId === id) {
          localStorage.setItem("selected-game-id", id);
          return game;
        }
      }
    } else {
      localStorage.removeItem("selected-game-id");
    }
  }

  async selectedGame(): Promise<Game|undefined> {
    const id = localStorage.getItem("selected-game-id");
    if (!id) return;
    const savedGames = await this.listAllGames();
    for (const game of savedGames) {
      if (game.gameId === id) {
        return game;
      }
    }
  }

  async upsertGame(game: Game): Promise<Game> {
    game = clone(game);
    const games = await this.listAllGames();
    if (game.gameId == null) {
      let nextId = 1;
      while (true) {
        if (!games.find(g => g.gameId === nextId + "")) {
          break;
        }
        nextId += 1;
      }  
      game.gameId = nextId+"";
      games.push(game);
    } else {
      let found = false;
      for (let i=0; i<games.length; i++) {
        if (games[i].gameId === game.gameId) {
          games[i] = game;
          found = true;
          break;
        }
      }
      if (!found) {
        throw new Error(`Game with id ${game.gameId} not found`);
      }

    }
    localStorage.setItem("games", JSON.stringify(games));
    return game;
  }

  async deleteGame(id: string): Promise<void> {
    const savedGames = await this.listAllGames();
    const newGames = [];
    let teamId = "";
    for (const game of savedGames) {
      if (game.gameId !== id) {
        newGames.push(game);
      } else {
        teamId = game.teamId;
      }
    }

    localStorage.setItem("games", JSON.stringify(newGames));
    const selectedGame = await this.selectedGame();
    if (selectedGame && selectedGame.gameId === id) {
      await this.selectGame();
    }

    // Any players from this team deleted? if so ... proceed to
    // check if the player is still referenced by any other game,
    // and if not => hard-delete the player
    if (teamId) {
      const players = (await this.listPlayers(teamId)) || [];
      for (const player of players) {
        if (player.deleted) {
          await this.deletePlayer(player.playerId || "");
        }
      }
    }  

    // todo: delete other related stuff
  }

  async upsertPlayer(player: Player): Promise<Player> {
    player = clone(player);
    const players = await this.listAllPlayers();
    if (player.playerId == null) {
      let nextId = 1;
      while (true) {
        if (!players.find(p => p.playerId === nextId + "")) {
          break;
        }
        nextId += 1;
      }  
      player.playerId = nextId+"";
      players.push(player);
    } else {
      let found = false;
      for (let i=0; i<players.length; i++) {
        if (players[i].playerId === player.playerId) {
          players[i] = player;
          found = true;
          break;
        }
      }
      if (!found) {
        throw new Error(`Game with id ${player.playerId} not found`);
      }

    }
    localStorage.setItem("players", JSON.stringify(players));
    return player;
  }

  async deletePlayer(id: string): Promise<Player|undefined> {
    const savedPlayers = await this.listAllPlayers();
    const newPlayers = [];
    let deletedPlayer: Player|undefined;
    for (const player of savedPlayers) {
      if (player.playerId !== id) {
        newPlayers.push(player);
      } else {
        player.deleted = true;
        const canHardDelete = await this._canHardDeletePlayer(player);
        if (!canHardDelete) {
          newPlayers.push(player);
          deletedPlayer = player;
        }
      }
    }
    localStorage.setItem("players", JSON.stringify(newPlayers));

    // todo: delete other related stuff

    return deletedPlayer;
  }

  async _canHardDeletePlayer(player: Player): Promise<boolean> {
    // Players are only marked-deleted unless all games they have played in have been deleted
    if (!player.deleted) return false;
    const thisPlayersTeamsGames = await this.listGames(player.teamId);
    const thisPlayerHasGames = thisPlayersTeamsGames.filter(g=>g.playerInfos.find(pi=>pi.playerId === player.playerId)).length > 0;
    return thisPlayerHasGames ? false: true;
  }

}

function clone<T>(obj: T): T {
  if (obj == null) return obj;
  return JSON.parse(JSON.stringify(obj)); 
}