import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button } from "reactstrap";
import {Player} from "../model/GameModel";

interface Props {
  isOpen: boolean;
  injuredPlayer: Player;
  candidates: Array<Player>;
  onSubmit: (replacingPlayerId: string)=>void;
  onCancel: ()=>void;
}

interface State {
  selectedPlayerId: string;
}

export default class InjuryReplacementSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {selectedPlayerId: ""};
  }

  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>Select a replacement</ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <FormGroup>
            <Label>Who should replace {this.props.injuredPlayer.name}?</Label>
            <Input type="select" value={this.state.selectedPlayerId} onChange={(e)=>this.setState({selectedPlayerId: e.target.value})}>
              <option value="">(no replacement)</option>
              {this.props.candidates.map(p=><option key={"sel-repl-player-"+p.playerId} value={p.playerId||""}>{p.name}</option>)}
            </Input>
          </FormGroup>
          <Button color="secondary" outline onClick={()=>this.props.onCancel()}>Cancel</Button>
          <Button color="primary" onClick={()=>this._handleSubmit()}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _handleSubmit() {
    this.props.onSubmit(this.state.selectedPlayerId!);
    this.setState({selectedPlayerId: ""});
  }

}

