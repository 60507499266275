import { inject, observer } from "mobx-react";
import * as React from "react";
import { Alert, Container, Row, Col, Form, FormGroup, Table, Button, Input } from "reactstrap";
import GameStore from "../store/GameStore";
import { Game, Player, GamePlayer, PlayLog, PlaySummary, PlayerStatus } from "../model/GameModel";
import GameAction from "./GameAction";
import InjuryReplacementSelect from "./InjuryReplacementSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
  gameStore?: GameStore;
}

interface State {
  modal: ""|"GameCancel";
  injuredPlayerId?: string;
  gamePlayTime: string;
  playTimeSinceSub: string;
  nextSubs: Array<SubAction>;
};

type SubActionAction ="replaces"|"re-enters game";

interface SubAction {
  playerId: string;
  action: SubActionAction;
  replacedPlayerId?: string;
};

interface PlayerInfo {
  p: Player;
  i: GamePlayer
  s?: SubAction;
};

@inject("gameStore")
@observer
export default class GameView extends React.Component<Props, State> {
  timer: NodeJS.Timer;
  mounted = false;
  constructor(props: Props) {
    super(props);
    this.state = {modal: "", gamePlayTime: "", playTimeSinceSub: "", nextSubs: []};
    this._onBackgroundTimer = this._onBackgroundTimer.bind(this);
    this.timer = setInterval(this._onBackgroundTimer, 1000);
  }

  async componentDidMount() {
    this.mounted = true;
  }

  _onBackgroundTimer() {
    if (this.mounted && this.props.gameStore) {
      let blankOut = true;
      if (this.props.gameStore.selectedGame) {
        
        if (this.props.gameStore.selectedGame.gameStartedAt) {
          const gamePlayTime = this._computeGameTime() || "";
          const playTimeSinceSub = this._computeCurrentPlayTime() || "";
          this.setState({gamePlayTime, playTimeSinceSub});
          blankOut = false;
        }
      }
      if (blankOut) {
        this.setState({gamePlayTime: "", playTimeSinceSub: ""});
      }
    }
  }

  render() {
    const game = this.props.gameStore!.selectedGame;
    if (!game) {
      return <p>No game selected</p>;
    }
    const allPlayers = this.props.gameStore!.selectedTeamPlayers || [];
    const availablePlayerInfo: PlayerInfo[] = [];
    const unavailablePlayerInfo: PlayerInfo[] = [];

    for (const info of game.playerInfos) {
      const p = allPlayers.find(p=>p.playerId === info.playerId);
      if (p) {
        const s = this.state.nextSubs.find(s=>s.playerId === info.playerId);
        const mergedInf = {p, i: info, s};
        if (info.status === "Unavailable") {
          unavailablePlayerInfo.push(mergedInf);
        } else {
          availablePlayerInfo.push(mergedInf);
        }
      }
    }
    const gameStarted = game.gameStartedAt ? true : false;
    if (!gameStarted) {
      return this.renderBeforeStart(game, availablePlayerInfo, unavailablePlayerInfo);
    }
    return this.renderActiveGame(game, availablePlayerInfo, unavailablePlayerInfo);    

  }

  renderBench(game: Game, availablePlayerInfo: PlayerInfo[], playSummaries: Map<string, PlaySummary>) {
    
    const benched = availablePlayerInfo.filter(p=>p.i.status === "Bench" || p.i.status === "Injured");
    if (benched.length === 0) {
      return null;
    }

    const makeReplacesText = (playerId?: string) => {
      const playerInfo = availablePlayerInfo.find(p=>p.p.playerId === playerId);
      if (!playerInfo) return "";
      const ps = playSummaries.get(playerId || "");
      if (!ps) return "";
      const minutes = Math.floor(ps.playTimeSeconds / 60);
      const isKeeper = playerInfo.i.status === "Keeper";
      return `[${ps.playsSinceBench} @ ${minutes}min] ${playerInfo.p.name}${isKeeper ? " (KEEPER)": ""}`;
    };

    const playing = availablePlayerInfo.filter(p=>p.i.status === "Player" || p.i.status === "Keeper")
      .sort((a, b)=> {
        const aSumm = playSummaries.get(a.p.playerId || "");
        const bSumm = playSummaries.get(b.p.playerId || "");
        if (!aSumm) return 1;
        if (!bSumm) return -1;
        if (aSumm.playsSinceBench < bSumm.playsSinceBench) return 1;
        if (aSumm.playsSinceBench > bSumm.playsSinceBench) return -1;
        if (aSumm.playTimeSeconds < bSumm.playsSinceBench) return 1;
        return -1;      
      });

    let canApplySubs = false;
    benched.forEach(b=>{
      switch (b.s?.action || "") {
        case "re-enters game":
          canApplySubs = true;
          break;
        case "replaces":
          if (b.s?.replacedPlayerId) {
            canApplySubs = true;
            break;
          }
          break;
      }
    });

    let benchCount = 1;

    // this makes the pick-list option value (not the text label)
    const makeReplacesValue = (subAction?: SubAction) => {
      if (!subAction) {
        return "";
      }
      if (subAction.action === "re-enters game") {
        return "re-enter-game";
      }
      if (subAction.action === "replaces" && subAction.replacedPlayerId) {
        return "replace:"+subAction.replacedPlayerId;
      }
      return "";
    };

    // make a list of selectable options per benched player as:
    // - if one benched player will replace another no other benched player should be able to select
    const optionsByBenchedPlayerId = new Map();
    const playerIdsSelectedForSub: Set<string> = new Set();
    let countOnFieldAfterNextSub = playing.length;
    benched.forEach(benchedPlayer=>{
      if (benchedPlayer.s) {
        if (benchedPlayer.s.replacedPlayerId) {
          playerIdsSelectedForSub.add(benchedPlayer.s.replacedPlayerId);
        } else if (benchedPlayer.s.action === "re-enters game") {
          countOnFieldAfterNextSub += 1;
        }
      }
    });
    
    const canReEnterGame = countOnFieldAfterNextSub < game.expectOnFieldPlayerCount;
    benched.forEach(benchedPlayer=>{
      const options: Array<any> = [<option key="sel-sub-player-none" value="">-</option>];
      if (canReEnterGame || (benchedPlayer.s && benchedPlayer.s.action === "re-enters game")) {
        options.push(<option key="sel-sub-player-reentergame" value="re-enter-game">No one (just re-enter game)</option>);
      }        
      playing.forEach(p=> {
        const isSelected = benchedPlayer.s && (benchedPlayer.s.replacedPlayerId === p.p.playerId);
        if (isSelected || !playerIdsSelectedForSub.has(p.p.playerId || "")) {
          options.push(<option key={"sel-sub-player-" + p.p.playerId} value={"replace:" + p.p.playerId||""}>{makeReplacesText(p.p.playerId)}</option>);
        }
      });
      optionsByBenchedPlayerId.set(benchedPlayer.p.playerId || "", options);
    });

    return <>
      <Alert color="primary">
        Benched player list - prepare substitions:&nbsp;
        {canApplySubs && <Button
          color="success"
          onClick={()=>this._handlePerformSub()}
        >
          SUBSTITUTE {this._isPaused() ? "& RESUME ": ""}NOW
        </Button>}
      </Alert>
      <Table>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              Name
            </th>
            <th>
              Injured?
            </th>
            <th>
              Replaces
            </th>
          </tr>
        </thead>
        <tbody>
          {benched.map(b=> {
            return <tr key={`benched-${b.p.playerId}`} className={benchCount%2 === 0 ? "table-secondary": ""}>
              <th scope="row" title={b.p.playerId}>{benchCount++}</th>
              <td>{b.p.name}</td>
              <td className="text-mid">
                <FormGroup switch>
                  <Input 
                    type="switch"
                    role="switch"
                    checked={(b.i.status) === "Injured"}
                    onChange={()=>this._handlePlayerInjuredToggle(b.p.playerId as string)}
                  />
                </FormGroup>
              </td>
              <td>
                <Input type="select" disabled={b.i.status==="Injured"} value={makeReplacesValue(b.s)} onChange={(e)=>this._handleSubPlayerChange(b.p.playerId||"", e.target.value)} >
                  {optionsByBenchedPlayerId.get(b.p.playerId || "")}
                </Input>
              </td>
            </tr>;
          })}
        </tbody>
      </Table>
    </>;
  }

  renderGameLineup(game: Game, availablePlayerInfo: PlayerInfo[], playSummaries: Map<string, PlaySummary>) {
    let playerCount = 1;
    const benchedPlayers = availablePlayerInfo.filter(p=>p.i?.status === "Bench");
    const injuredPlayerInfo = this.state.injuredPlayerId  ? availablePlayerInfo.find(p=>p.p.playerId === this.state.injuredPlayerId) : undefined;

    return <>
      <Alert color="info">
        Todays lineup - review player status:
      </Alert>
      {injuredPlayerInfo && <InjuryReplacementSelect
        isOpen={this.state.injuredPlayerId ? true : false}
        onCancel={()=>this.setState({injuredPlayerId: undefined})}
        onSubmit={async (replacingPlayerId: string)=>{
          await this._handleInjuredPlayerReplacement(this.state.injuredPlayerId || "", replacingPlayerId);
          this.setState({injuredPlayerId: undefined});
        }}
        candidates={benchedPlayers.map(p=>p.p)}
        injuredPlayer={injuredPlayerInfo.p}
      />}
      <Form>
        <Table>
          <thead>
            <tr>
              <th>
              #
              </th>
              <th>
              Name
              </th>
              <th>
              Injured?
              </th>
              <th>
              Status
              </th>
              <th>
              Plays
              </th>
              <th>
              Mins
              </th>
              <th>
              Plays since bench
              </th>
            </tr>
          </thead>
          <tbody>
            {availablePlayerInfo.map(p=> {
              return <tr key={`player-${p.p.playerId}`} className={playerCount%2 === 0 ? "table-secondary": ""}>
                <th scope="row" title={p.p.playerId}>{playerCount++}</th>
                <td>{p.p.name}</td>
                <td className="text-mid">
                  <FormGroup switch>
                    <Input 
                      type="switch"
                      role="switch"
                      checked={p.i.status === "Injured"}
                      onChange={()=>this._handlePlayerInjuredToggle(p.p.playerId as string)}
                    />
                  </FormGroup>
                </td>
                <td>{this._statusIcon(p.i.status)}</td>
                <td>{playSummaries.get(p.p.playerId || "")?.playsStarted}</td>
                <td>{Math.floor((playSummaries.get(p.p.playerId || "")?.playTimeSeconds || 1)/60)}</td>
                <td>{playSummaries.get(p.p.playerId || "")?.playsSinceBench}</td>
              </tr>;
            })}
          </tbody>
        </Table>
      </Form>
    </>;
  }

  renderBeforeStart(game: Game, availablePlayerInfo: PlayerInfo[], unavailablePlayerInfo: PlayerInfo[]) {
    const instructions: string[] = [];
    const keepers = availablePlayerInfo.filter(p=>p.i.status === "Keeper");
    let canStart = true;
    if (keepers.length === 0) {
      instructions.push("Select a keeper");
      canStart = false;

    } else if (keepers.length > 1) {
      instructions.push(`Too many keepers selected (${keepers.map(k=>k.p.name).join(", ")})`);
      canStart = false;
    }

    const expectedCount = (game.expectOnFieldPlayerCount || 9);
    const selectedPlayersCount = availablePlayerInfo.filter(p=>p.i.status === "Player" || p.i.status === "Keeper").length;
    if (selectedPlayersCount < expectedCount) {
      instructions.push(`Select ${expectedCount} players to be on-field at game start (need ${expectedCount-selectedPlayersCount} more), or edit the game to change the required player count`);
      canStart = false;
    }
    if (selectedPlayersCount > expectedCount) {
      instructions.push(`Move ${selectedPlayersCount - expectedCount} player(s) to the bench`);
      canStart = false;
    }
    let playerCount = 1;

    return <>
      <Container fluid>
        <Row>
          <Col className="text-start">
            <b>Prepare your stating lineup:</b>
            <ul>
              {instructions.map(i=><li key={i}>{i}</li>)}
            </ul>
          </Col>
        </Row>
      </Container>
      <Button
        color={canStart ? "success": "warning"}
        disabled={!canStart}
        onClick={()=>this._handleGameStart()}
      >
        {canStart ? "Start this Game!" : "Fix Lineup to Start"}
      </Button>
      <Form>
        <Table>
          <thead>
            <tr>
              <th style={{width: "10%"}}>
              #
              </th>
              <th style={{width: "40%"}}>
              Name
              </th>
              <th style={{width: "10%"}} className="text-start">
              Playing?
              </th>
              <th style={{width: "35%"}}>
              Position
              </th>
              <th style={{width: "5%"}}></th>
            </tr>
          </thead>
          <tbody>
            {availablePlayerInfo.map(p=> {
              return <tr key={`player-${p.p.playerId}`} className={playerCount%2 === 0 ? "table-secondary": ""}>
                <th scope="row" title={p.p.playerId}>{playerCount++}</th>
                <td>{p.p.name}</td>
                <td className="text-mid">
                  <FormGroup switch>
                    <Input 
                      type="switch"
                      role="switch"
                      checked={(game.playerInfos || []).find(i=>i.playerId===p.p.playerId)?.status !== "Unavailable"}
                      onChange={()=>this._handlePlayerAvailableChange(p.p.playerId as string)}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup>
                    <Input 
                      type="select"
                      bsSize="sm"
                      disabled={p.i.status === "Unavailable"}
                      value={p.i.status || ""}
                      onChange={(e)=>this._handlePlayerStatusChange(p.p.playerId as string, e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Keeper">Keeper</option>
                      <option value="Player">Player</option>
                      <option value="Bench">Bench</option>
                    </Input>
                  </FormGroup>
                </td>
                <td>{this._statusIcon(p.i.status)}</td>
              </tr>;
            })}
          </tbody>
        </Table>
        <br/>
        <Container fluid>
          <Row>
            <Col className="text-start">
              <b>Unavailable players:</b> Not playing today
            </Col>
          </Row>
        </Container>    

        <Table>
          <thead>
            <tr>
              <th style={{width: "10%"}}>
              #
              </th>
              <th style={{width: "40%"}}>
              Name
              </th>
              <th style={{width: "50%"}} className="text-start">
              Playing
              </th>
            </tr>
          </thead>
          <tbody>
            {unavailablePlayerInfo.map(p=> {
              return <tr key={`benchedplayer-${p.p.playerId}`} className={playerCount%2 === 0 ? "table-secondary": ""}>
                <th scope="row" title={p.p.playerId}>{playerCount++}</th>
                <td>{p.p.name}</td>
                <td>
                  <FormGroup switch>
                    <Input 
                      type="switch"
                      role="switch"
                      defaultChecked={(game.playerInfos || []).find(i=>i.playerId===p.p.playerId)?.status !== "Unavailable"}
                      onClick={()=>this._handlePlayerAvailableChange(p.p.playerId as string)}
                    />
                  </FormGroup>
                </td>
              </tr>;
            })}
          </tbody>
        </Table>
      </Form>
    </>;
  }

  renderActiveGame(game: Game, availablePlayerInfo: PlayerInfo[], unavailablePlayerInfo: PlayerInfo[]) {
    let modalTitle = "";
    let modalMessage = "";
    let modalWarning = false;
    let modalOnOk = () => {};
    switch (this.state.modal) {
      case "GameCancel":
        modalTitle = "ABORT GAME";
        modalMessage = "Click OK to cancel the running game. All game information will be destroyed, this cannot be undone.";
        modalWarning = true;
        modalOnOk = ()=>{
          this._handleGameCancel();
          this.setState({modal: ""});
        };
        break;
    }

    const playSummaries = this._computePlaySummaries(game, availablePlayerInfo);
    return <>
      <GameAction
        isOpen={this.state.modal ? true : false}
        game={this.props.gameStore!.selectedGame as Game}
        title={modalTitle} message={modalMessage}
        danger={modalWarning}
        onCancel={()=>this.setState({modal: ""})}
        onOk={()=>modalOnOk()}
      />
      <Container fluid>
        <br/>
        <Row>
          <Col>
            <b>Game</b>: {this.state.gamePlayTime}
          </Col>
          <Col>
            <b>Play #{this.props.gameStore!.selectedGame?.plays.length}</b>: {this.state.playTimeSinceSub}
          </Col>
          <Col>
            <Button size="sm" outline onClick={()=> this._handleGamePauseOrResume()}>{game.pausedTime ? "Resume" : "Pause"}</Button>
          </Col>
        </Row>
      </Container>
      {this.renderBench(game, availablePlayerInfo, playSummaries)}<br/>
      <br/>
      {this.renderGameLineup(game, availablePlayerInfo, playSummaries)}
      <Button color="warning" onClick={()=>this.setState({modal: "GameCancel"})}>Abort this Game</Button>
    </>;    
  }

  _statusIcon(status?: PlayerStatus) {
    switch (status || "") {
      case "Player":
        return <FontAwesomeIcon icon={icon({name: "person-running"})} shake/>;
      case "Keeper":
        return <FontAwesomeIcon icon={icon({name: "child-reaching"})} bounce />;
      case "Injured":
        return <FontAwesomeIcon icon={icon({name: "user-injured"})} />;
      case "Bench":
        return <FontAwesomeIcon icon={icon({name: "chair"})} />;        
    }
    return status;
  }

  // summarize the total game stats for each player
  _computePlaySummaries(game: Game, availablePlayerInfo: PlayerInfo[]) {

    // Iterate each player
    const playSummaryByPlayerId: Map<string, PlaySummary> = new Map();
    const now = Date.now();
    for (const p of availablePlayerInfo) {
      const summary: PlaySummary = {
        benchesStarted: 0, // x
        playsStarted: 0, // x
        playsLeftInjured: 0, // x
        playsReplacedInjured: 0, // x
        playsSinceBench: 0, // x
        playTimeSeconds: 0
      };
      playSummaryByPlayerId.set(p.p.playerId || "", summary);

      // Iterate each play
      for (const play of game.plays) {
        const playStatus = play.playerStatus.find(ps=>ps.playerId === p.i.playerId);
        if (!playStatus) continue;

        const startedPlaying = playStatus.status === "Player" || playStatus.status === "Keeper";
        const injuriesDuringThisPlay = play.injuryReplacements.filter(r=>r.injuredPlayerId === playStatus.playerId);
        const replacedOthersThisPlay = play.injuryReplacements.filter(r=>r.replacingPlayerId === playStatus.playerId);
        if (startedPlaying) {
          summary.playsStarted += 1;
          summary.playsSinceBench += 1;

          // In this play: did they go off injured?
          // Only count "left injured" once per play, regardless of whether they left & re-entered multiple times
          summary.playsLeftInjured += injuriesDuringThisPlay.length > 0 ? 1 : 0;          
        } else {
          summary.benchesStarted += 1;
          summary.playsSinceBench = 0;
          
          // In this play: did they replace an injured player?
          // Only count "replaced injured" once per play, regardless of whether they entered+left multiple times
          summary.playsReplacedInjured += replacedOthersThisPlay.length > 0 ? 1 : 0;          
        }
  
        // complete plays have an end time; otherwise, if game is paused use that as the end time, else use now (active play)
        const playEndTime = play.endTime || game.pausedTime || now;

        // When computing duration for this play:
        // 1. A player may start on the field, and play the full duration
        // 2. A player may be on the field, and exit the field injured before the play completes
        // 3. A player may be off the field, and enter the field to replace an injury before the play completes
        // 4. The game may be paused multiple times during the play
        // so, build an array of start=>ends that account for on-field, not-paused time during the play
        const onFieldTime: Array<{startTime: number, endTime: number}> = [];
        const findNextPauseTimeOrExitInjuredTime = (after: number): {exitTime: number; injured: boolean} => {
          let exitTime = playEndTime;
          let injured = false;
          const nextPauseLog = play.pauses.find(p=>p.startTime > after);
          if (nextPauseLog && nextPauseLog.startTime < exitTime) {
            exitTime = nextPauseLog.startTime;
          }
          const nextInjuryExit = injuriesDuringThisPlay.find(i=>i.injuryTime > after);
          if (nextInjuryExit && nextInjuryExit.injuryTime < exitTime) {
            exitTime = nextInjuryExit.injuryTime;
            injured = true;
          }
          return {exitTime, injured};
        };
        const findNextResumeTime = (after: number): number|undefined => {
          let resumeTime = playEndTime;
          const nextPauseLog = play.pauses.find(p=>(p.endTime || playEndTime) >= after);
          if (nextPauseLog && ((nextPauseLog.endTime || playEndTime) < resumeTime)) {
            resumeTime = nextPauseLog.endTime || playEndTime;
          }
          return resumeTime;
        };
        const findNextReEnterTime = (after: number): number|undefined => {
          let reEnterTime: number|undefined;
          const nextReplaceInjured = replacedOthersThisPlay.find(i=>i.injuryTime >= after);
          if (nextReplaceInjured && nextReplaceInjured.injuryTime < playEndTime) {
            reEnterTime = nextReplaceInjured.injuryTime;
          }          
          return reEnterTime;
        };
        let nextStartTime: number|undefined;   
        if (startedPlaying) {
          nextStartTime = play.startTime;
        } else if (replacedOthersThisPlay.length > 0) {
          nextStartTime = replacedOthersThisPlay[0].injuryTime;
        }
        let nextExitTime = 0;
        let loopCount = 0; // fail-safe
        while (nextStartTime) {
          const nextBreak = findNextPauseTimeOrExitInjuredTime(nextStartTime);
          nextExitTime = nextBreak.exitTime;
          onFieldTime.push({startTime: nextStartTime, endTime: nextExitTime});

          // Possible that the game resumes/player re-enters before end?
          if (nextExitTime >= playEndTime) {
            break;
          }
          const leftInjured = nextBreak.injured;
          if (leftInjured) {
            nextStartTime = findNextReEnterTime(nextBreak.exitTime);
          } else {
            nextStartTime = findNextResumeTime(nextBreak.exitTime);
          }
          loopCount++;
          if (loopCount >= 100) {
            throw new Error("Loop issue when computing stats!");
          }
        }

        let playedDurationMs = 0;
        onFieldTime.forEach(oft => {
          const dur = oft.endTime - oft.startTime;
          if (dur >0) {
            playedDurationMs += dur;
          }
        });
      
        const playedDurationSec = playedDurationMs <= 0 ? 0 : Math.floor(playedDurationMs / 1000);
        summary.playTimeSeconds += playedDurationSec;
      }
    }

    return playSummaryByPlayerId;
  }

  // computes the total time the game has been playing, excluding any pauses
  _computeGameTime() {
    let currentTime = this.props.gameStore?.selectedGame?.pausedTime ? this.props.gameStore?.selectedGame?.pausedTime : Date.now();
    const playTime = currentTime - (this.props.gameStore?.selectedGame?.gameStartedAt || 0) -
    ((this.props.gameStore!.selectedGame!.pausedSecondsPriorSubs) || 0) * 1000 - 
    ((this.props.gameStore!.selectedGame!.pausedSecondsThisSub) || 0) * 1000;

    if (playTime <= 0) {
      return null;
    }

    return msToTime(playTime);
  }

  // computes the time spent on the current play, excluding any pauses
  _computeCurrentPlayTime() {
    let currentTime = this.props.gameStore?.selectedGame?.pausedTime ? this.props.gameStore?.selectedGame?.pausedTime : Date.now();
    const playTime = currentTime - (this.props.gameStore?.selectedGame?.lastPlayAt || 0) -
    (this.props.gameStore!.selectedGame!.pausedSecondsThisSub || 0) * 1000;
    if (playTime <= 0) {
      return null;
    }
    return msToTime(playTime);
  }

  async _handleGameSelect(id?: string) {
    await this.props.gameStore?.selectGame(id);
  }

  async _handleGameStart() {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    newGame.gameStartedAt = Date.now();
    newGame.lastPlayAt = newGame.gameStartedAt;
    if (!newGame.plays) {
      newGame.plays = [];
    }
    const playLog: PlayLog = {
      playNumber: newGame.plays.length,
      startTime: newGame.gameStartedAt,
      playerStatus: [],
      injuryReplacements: [],
      pauses: [],
    };
    for (const p of newGame.playerInfos) {
      if (p.status && p.status !== "Unavailable") {
        playLog.playerStatus.push({playerId: p.playerId, status: p.status || "Bench"});
      }
    }
    newGame.plays.push(playLog);
    await this._handleGameUpsert(newGame);
  }

  async _handleGamePauseOrResume() {
    const game = this.props.gameStore?.selectedGame;
    if (game?.pausedTime) {
      return this._handleGameResume();
    } else {
      return this._handleGamePause();
    }
  }

  async _handleSubPlayerChange(playerId: string, action: string) {
    const oldSbActions = this.state.nextSubs;
    let newSbActions = (JSON.parse(JSON.stringify(oldSbActions)) as Array<SubAction>).filter(a=>a.playerId !== playerId);
    if (action) {
      if (action === "re-enter-game") {
        newSbActions.push({playerId, action: "re-enters game"});
      } else if (action.startsWith("replace:")) {
        const replacedPlayerId = action.split(":")[1];
        newSbActions.push({playerId, action: "replaces", replacedPlayerId});
      }
    }
    this.setState({nextSubs: newSbActions});
  }

  async _handleGamePause() {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    newGame.pausedTime = Date.now();
    const currentPlay = newGame.plays[newGame.plays.length -1];
    currentPlay.pauses.push({startTime: newGame.pausedTime});
    await this._handleGameUpsert(newGame);
  }

  async _handleGameResume() {
    if (!this._isPaused()) return;

    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    const endTime = Date.now();
    const durationSec = Math.floor((endTime - game!.pausedTime!)/1000);
    newGame.pausedSecondsThisSub = (newGame.pausedSecondsThisSub || 0) + durationSec;
    newGame.pausedTime = undefined;
    const currentPlay = newGame.plays[newGame.plays.length - 1];
    const currentPause = currentPlay.pauses[currentPlay.pauses.length-1];
    currentPause.endTime = endTime;
    await this._handleGameUpsert(newGame);
  }

  _isPaused() {
    const game = this.props.gameStore?.selectedGame;
    return game?.pausedTime ? true : false;
  }

  async _handleGameCancel() {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    newGame.gameStartedAt = undefined;
    newGame.pausedTime = undefined;
    newGame.pausedSecondsPriorSubs = 0;
    newGame.pausedSecondsThisSub = 0;
    newGame.plays = [];
    await this._handleGameUpsert(newGame);
  }

  async _handlePerformSub() {
    // if paused: resume first to close out the pause action
    await this._handleGameResume();

    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    newGame.lastPlayAt = Date.now();
    newGame.pausedSecondsPriorSubs += newGame.pausedSecondsThisSub || 0;
    newGame.pausedSecondsThisSub = 0;

    const lastPlay = newGame.plays[newGame.plays.length - 1];

    // Close any active pause
    const latestPause = lastPlay.pauses[lastPlay.pauses.length -1];
    if (latestPause && !latestPause.endTime) {
      latestPause.endTime = newGame.lastPlayAt;
    }

    // Close out the last sub time
    lastPlay.endTime = newGame.lastPlayAt;
    const playLog: PlayLog = {
      playNumber: newGame.plays.length,
      startTime: newGame.lastPlayAt,
      playerStatus: [],
      injuryReplacements: [],
      pauses: [],
    };

    // Update player status as-per the next subs actions
    for (const action of this.state.nextSubs) {
      const playerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === action.playerId);
      const replacedPlayerInfo = action.replacedPlayerId ? newGame && newGame.playerInfos.find(i=>i.playerId === action.replacedPlayerId) : undefined;
      if (!playerInfo) continue;
      playerInfo.status = replacedPlayerInfo && replacedPlayerInfo.status === "Keeper" ? "Keeper" : "Player";
      switch (action.action || "") {
        case "re-enters game":
          // no-op
          break;
        case "replaces":
          if (replacedPlayerInfo) {
            replacedPlayerInfo.status = "Bench";
          }
          break;
      }
    }
    for (const p of newGame.playerInfos) {
      if (p.status && p.status !== "Unavailable") {
        playLog.playerStatus.push({playerId: p.playerId, status: p.status || "Bench"});
      }
    }
    newGame.plays.push(playLog);

    await this._handleGameUpsert(newGame);
  }

  async _handlePlayerAvailableChange(playerId: string) {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    const playerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === playerId);
    if (playerInfo) {
      const wasUnavailable = playerInfo.status === "Unavailable";
      const newStatus = wasUnavailable ? "Bench" : "Unavailable";
      playerInfo.status = newStatus;
      await this._handleGameUpsert(newGame);
    }
  }

  async _handlePlayerInjuredToggle(playerId: string) {

    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    const newPlayerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === playerId);
    if (newPlayerInfo) {
      // if the game has started and the player is playing => allow selection of the replacement
      if (game?.gameStartedAt && (newPlayerInfo.status === "Player" || newPlayerInfo.status === "Keeper")) {
        this.setState({injuredPlayerId: playerId});
      } else {
        const wasInjured = newPlayerInfo.status === "Injured";
        const newStatus = wasInjured ? "Bench" : "Injured";
        newPlayerInfo.status = newStatus;
        await this._handleGameUpsert(newGame);
        if (newStatus === "Injured") {
          const newSubActions = (JSON.parse(JSON.stringify(this.state.nextSubs)) as SubAction[]).filter(a=>a.playerId !== playerId);
          this.setState({nextSubs: newSubActions});
        }
      }
    }
  }

  async _handleInjuredPlayerReplacement(injuredPlayerId: string, replacingPlayerId: string) {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    const newInjuredPlayerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === injuredPlayerId);
    let replacedPlayerStatus: PlayerStatus = "Player";
    let newSubActions = (JSON.parse(JSON.stringify(this.state.nextSubs)) as SubAction[]);
    if (newInjuredPlayerInfo) {
      replacedPlayerStatus = newInjuredPlayerInfo.status || "Player";
      newInjuredPlayerInfo.status = "Injured";
      newSubActions = newSubActions.filter(a=>a.replacedPlayerId !== newInjuredPlayerInfo.playerId); 
    }
    const newReplacingPlayerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === replacingPlayerId);
    if (newReplacingPlayerInfo) {
      newReplacingPlayerInfo.status = replacedPlayerStatus;
      newSubActions.filter(a=>a.playerId !== newReplacingPlayerInfo.playerId);
    }
    const playToUpdate = newGame.plays[newGame.plays.length-1];
    playToUpdate.injuryReplacements.push({injuryTime: Date.now(), replacingPlayerId, injuredPlayerId});
    await this._handleGameUpsert(newGame);
    this.setState({nextSubs: newSubActions});
  }

  async _handlePlayerStatusChange(playerId: string, status: string) {
    const game = this.props.gameStore?.selectedGame;
    const newGame = JSON.parse(JSON.stringify(game)) as Game;
    const playerInfo = newGame && newGame.playerInfos.find(i=>i.playerId === playerId);
    if (playerInfo) {
      playerInfo.status = status as any;
      await this._handleGameUpsert(newGame);
    }
  }

  async _handleGameUpsert(game: Game) {
    await this.props.gameStore?.upsertGame(game);
    
  }

  async _handleGameDelete(id: string) {
    this.setState({modal: ""});
    await this.props.gameStore?.deleteGame(id);
  }

}

function msToTime(duration: number) {
  //let milliseconds: number;
  //let hours: number|string;
  let minutes: number|string;
  let seconds: number|string;

  //milliseconds = Math.floor((duration % 1000) / 100),
  seconds = Math.floor((duration / 1000) % 60),
  minutes = Math.floor((duration / (1000 * 60))),

  //minutes = Math.floor((duration / (1000 * 60)) % 60),
  //hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  //hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return minutes + ":" + seconds;
  //return hours + ":" + minutes + ":" + seconds;
}