import { inject, observer } from "mobx-react";
import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button } from "reactstrap";
import GameStore from "../store/GameStore";

interface Props {
  gameStore?: GameStore;
  isOpen: boolean;
  onSubmit: (value: string)=>void;
  onCancel: ()=>void;
}

interface State {
  name: string;
}

@inject("gameStore")
@observer
export default class TeamAdd extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {name: ""};
  }
  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>
            Add your team:
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input placeholder="Give your team a name" onChange={(e)=>this.setState({name: e.target.value})}/>
          </FormGroup>
          <Button color="secondary" outline onClick={()=>this.props.onCancel()} disabled={this.props.gameStore?.teams.length===0}>Cancel</Button>
          <Button color="primary" disabled={this.state.name === ""} onClick={()=>this._handleSubmit()}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _handleSubmit() {
    this.props.onSubmit(this.state.name);
    this.setState({name: ""});
  }

}

