import { inject, observer } from "mobx-react";
import * as React from "react";
import { Table, Button, FormGroup, Input, Label, UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem  } from "reactstrap";
import GameStore from "../store/GameStore";
import { Player } from "../model/GameModel";
import PlayerEdit from "../components/PlayerEdit";
import GenericDelete from "../components/GenericDelete";

interface Props {
  gameStore?: GameStore;
}

interface State {
  modal: ""|"PlayerAdd"|"PlayerEdit"|"PlayerDelete";
  eventPlayer?: Player;
  showDeleted: boolean;
};

@inject("gameStore")
@observer
export default class PlayersGrid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {showDeleted:false, modal: ""};
  }

  render() {
    let rowCount = 1;
    return <>
      <FormGroup check inline>
        <Input type="checkbox" defaultChecked={this.state.showDeleted} onChange={()=>this.setState({showDeleted: !this.state.showDeleted })}/>
        <Label check>Show deleted players?</Label>
      </FormGroup>
      {(this.state.modal==="PlayerEdit" || this.state.modal==="PlayerAdd") &&
        <PlayerEdit isOpen={true} player={this.state.eventPlayer!} onCancel={()=>this._handleModalCancel()} onSubmit={(player)=>this._handlePlayerSave(player)}/>
      }
      <GenericDelete
        isOpen={this.state.modal==="PlayerDelete"}
        onCancel={()=>this._handleModalCancel()}
        onSubmit={(id)=>this._handlePlayerDelete(id)}
        itemId={this.state.eventPlayer?.playerId || ""}
        itemType="Player"
        itemName={this.state.eventPlayer?.name ||"" }
      />
      <Table>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              Name
            </th>
            {this.state.showDeleted && <th>
              Deleted?
            </th>}
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.gameStore?.selectedTeamPlayers.filter(p=>this.state.showDeleted || !p.deleted).map(p=> {
            return <tr key={p.playerId}>
              <th scope="row"title={p.playerId}>{rowCount++}</th>
              <td>{p.name}</td>
              {this.state.showDeleted && <td><Input type="checkbox" disabled defaultChecked={p.deleted}/></td> }
              <td>
                <UncontrolledDropdown group>
                  <Button outline color="primary" size="sm" onClick={()=>this.setState({modal: "PlayerEdit", eventPlayer: p})}>Edit</Button>
                  <DropdownToggle
                    caret
                    color="primary"
                    size="sm"
                  />
                  <DropdownMenu>
                    <DropdownItem onClick={()=> this.setState({eventPlayer: p, modal: "PlayerDelete"})} size="sm">
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown></td>
            </tr>;
          })}
        </tbody>
      </Table>
      <Button color="primary" onClick={()=>this.setState({modal: "PlayerAdd", eventPlayer: {teamId: this.props.gameStore!.selectedTeamId, name: ""}})}>New Player</Button>
    </>;
  }

  _handleModalCancel() {
    this.setState({modal: ""});
  }

  async _handlePlayerSave(player: Player) {
    this.setState({modal: "", eventPlayer: undefined});
    await this.props.gameStore?.upsertPlayer(player);
  }

  async _handlePlayerDelete(id: string) {
    this.setState({modal: "", eventPlayer: undefined});
    await this.props.gameStore?.deletePlayer(id);
  }

}

