import { action, configure, makeObservable, observable, runInAction } from "mobx";
import {Service, Game, Team, Player} from "../model/GameModel";

configure({ enforceActions: "always" });

export default class GameStore {
  @observable games: Game[] = [];
  @observable teams: Team[] = [];
  @observable selectedTeamId: string = "";
  @observable selectedTeamName: string = "";
  @observable selectedTeamPlayers: Player[] = [];
  @observable selectedGame: Game|undefined;
  service: Service;
  private isInit = false;
  constructor(service: Service) {
    this.service = service;
    makeObservable(this);
  }
  
  async init() {
    if (this.isInit) return;
    this.isInit = true;
    const newTeams = (await this.service.listTeams()).sort((a, b)=>a.name < b.name ? -1 :1);
    let selectedTeam = await this.service.selectedTeam();
    if (!selectedTeam && (newTeams || []).length > 0) {
      selectedTeam = newTeams[0];
    }
    const newGames = (selectedTeam && selectedTeam.teamId ? await this.service.listGames(selectedTeam.teamId) : []).sort((a,b)=>a.date<b.date ? 1: -1);
    const selectedGame = await this.service.selectedGame();
    runInAction(async()=>{
      this.games.length = 0;
      this.teams.length = 0;
      for (const game of newGames) {
        this.games.push(game);
      }
      for (const team of newTeams) {
        this.teams.push(team);
      }
      const selectedTeamId = selectedTeam && selectedTeam.teamId || "";
      if (selectedTeamId) {
        await this.selectTeam(selectedTeamId);
      }
    });
    await this.selectGame(selectedGame && selectedGame.gameId);
  }

  async selectTeam(id?: string) {
    const newTeam = await this.service.selectTeam(id);
    const players = ((await this.service.listPlayers(id ||"")) || []).sort((a, b)=>a.name < b.name ? -1 : 1);
    runInAction(async()=>{      
      this.selectedTeamId = newTeam && newTeam.teamId || "";
      this.selectedTeamName = newTeam && newTeam.name || "";
      if (this.selectedTeamId) {
        this.selectedTeamPlayers = players;

      } else {
        this.selectedTeamPlayers.length = 0;
      }
    });
  }

  async upsertTeam(team: Team) {
    team = await this.service.upsertTeam(team);
    runInAction(async()=>{

      let found = false;
      for (let i=0; i<this.teams.length; i++) {
        if (this.teams[i].teamId === team.teamId) {
          this.teams[i] = team;
          found = true;
        }
      }
      if (!found) {
        this.teams.push(team);
      }
      this.teams.sort((a,b)=> a.name < b.name ? -1 : 1);
    });
  }

  async deleteTeam(id: string) {
    await this.service.deleteTeam(id);
    runInAction(async()=>{
      for (let i=this.teams.length-1; i>=0; i--) {
        if (this.teams[i].teamId === id) {
          this.teams.splice(i, 1);
        }
      }
    });
  }

  async selectGame(id?: string) {
    const newGame = await this.service.selectGame(id);
    runInAction(async()=>{
      this.selectedGame = newGame;
    });
  }

  async upsertGame(game: Game): Promise<Game> {
    game = await this.service.upsertGame(game);
    const newGames: Game[] = []; 
    let found = false;
    for (let i=0; i<this.games.length; i++) {
      if (this.games[i].gameId === game.gameId) {
        newGames.push(game);
        found = true;
      } else {
        newGames.push(this.games[i]);
      }
    }
    if (!found) {
      newGames.push(game);
    }
    newGames.sort((a, b)=> a.date < b.date ? 1 : -1);

    runInAction(async()=>{
      this.games = newGames;
      if (this.selectedGame && this.selectedGame.gameId === game.gameId) {
        this.selectGame(game.gameId);
      }
    });
    return game;
  }

  async deleteGame(id: string) {
    await this.service.deleteGame(id);
    runInAction(async()=>{
      for (let i=this.games.length-1; i>=0; i--) {
        if (this.games[i].gameId === id) {
          this.games.splice(i, 1);
        }
      }
      // Deleting a ame may trigger hard-delete of players
      if (this.selectedTeamId) {
        await this.selectTeam(this.selectedTeamId);
      }
    });
    if (this.selectedGame && this.selectedGame.gameId === id) {
      this.selectGame();
    }
  }

  async upsertPlayer(player: Player) {
    player = await this.service.upsertPlayer(player);
    runInAction(async()=>{
      let found = false;
      for (let i=0; i<this.selectedTeamPlayers.length; i++) {
        if (this.selectedTeamPlayers[i].playerId === player.playerId) {
          this.selectedTeamPlayers[i] = player;
          found = true;
        }
      }
      if (!found) {
        this.selectedTeamPlayers.push(player);
      }
      this.selectedTeamPlayers.sort((a, b)=> a.name < b.name ? -1 : 1);
    });
  }

  @action.bound
  async deletePlayer(id: string) {
    const newPlayer = await this.service.deletePlayer(id);
    runInAction(async()=>{
      for (let i=this.selectedTeamPlayers.length-1; i>=0; i--) {
        if (this.selectedTeamPlayers[i].playerId === id) {
          if (newPlayer) {
            this.selectedTeamPlayers[i] = newPlayer;
          } else {
            this.selectedTeamPlayers.splice(i, 1);
          }
        }
      }
    });
  }
 
}