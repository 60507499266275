import { inject, observer } from "mobx-react";
import * as React from "react";
import { Table, Button, UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem, Modal  } from "reactstrap";
import GameStore from "../store/GameStore";
import { Game, GamePlayer } from "../model/GameModel";
import GameEdit from "./GameEdit";
import GenericDelete from "../components/GenericDelete";

interface Props {
  gameStore?: GameStore;
  changeTab: (tab: "Game")=>void;
}

interface State {
  modal: ""|"GameAdd"|"GameEdit"|"GameDelete";
  eventGame?: Game;
};

@inject("gameStore")
@observer
export default class GamesGrid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {modal: ""};
  }

  render() {
    let rowCount = 1;

    return <>
      {(this.state.modal==="GameAdd"||this.state.modal==="GameEdit") &&
        <GameEdit isOpen={true} game={this.state.eventGame!} onCancel={()=>this._handleModalCancel()} onSubmit={(game)=>this._handleGameUpsert(game)}/>
      }
      <GenericDelete
        isOpen={this.state.modal==="GameDelete"}
        onCancel={()=>this._handleModalCancel()}
        onSubmit={(id)=>this._handleGameDelete(id)}
        itemId={this.state.eventGame?.gameId || ""}
        itemType="Team"
        itemName={this.state.eventGame?.title ||"" }
      />
      <Table>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              Name
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.gameStore?.games.map(g=> {
            return <tr key={`game-${g.gameId}`} className={this.props.gameStore?.selectedGame?.gameId === g.gameId ? "table-primary": ""}>
              <th scope="row" title={g.gameId}>{rowCount++}</th>
              <td>{g.title}</td>
              <td>
                <UncontrolledDropdown group>
                  <Button onClick={()=> this.setState({eventGame: g, modal: "GameEdit"})} outline color="primary" size="sm">Edit</Button>
                  <DropdownToggle
                    caret
                    color="primary"
                    size="sm"
                  />
                  <DropdownMenu>
                    <DropdownItem onClick={()=> this._handleGameSelect(g.gameId)} size="sm">
                      Select
                    </DropdownItem>
                    <DropdownItem onClick={()=> this.setState({eventGame: g, modal: "GameDelete"})} size="sm">
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown></td>
            </tr>;
          })}
        </tbody>
      </Table>
      <Button
        color="primary"
        onClick={()=>{
          this.setState({
            modal: "GameAdd",
            eventGame: {
              title: "",
              teamId: this.props.gameStore!.selectedTeamId || "",
              expectOnFieldPlayerCount: 9,
              playerInfos: this.props.gameStore!.selectedTeamPlayers.map(p=>{return {playerId: p.playerId ||"", available: true, status: "Player"};}),
              date: new Date().toISOString(),
              subIntervalMins: 5,
              pausedSecondsPriorSubs: 0,
              pausedSecondsThisSub: 0,
              plays: []
            }
          });
        }}>
          New Game
      </Button>
    </>;
  }

  async _handleGameSelect(id?: string) {
    await this.props.gameStore?.selectGame(id);
  }

  _handleModalCancel() {
    this.setState({modal: ""});
  }

  async _handleGameUpsert(game: Game) {
    this.setState({modal: "", eventGame: undefined});
    const isAdd = game.gameId ? false : true;
    const newGame = await this.props.gameStore!.upsertGame(game);
    await this.props.gameStore?.selectGame(newGame.gameId);
    if (isAdd) {
      this.props.changeTab("Game");
    }

  }

  async _handleGameDelete(id: string) {
    this.setState({modal: "", eventGame: undefined});
    await this.props.gameStore?.deleteGame(id);
  }

}

