import { inject, observer } from "mobx-react";
import * as React from "react";

import {Container, Row, Col, Nav, NavItem, NavLink, TabContent} from "reactstrap";
import GameStore from "./store/GameStore";
import TeamsGrid from "./components/TeamsGrid";
import PlayersGrid from "./components/PlayersGrid";
import GamesGrid from "./components/GamesGrid";
import GamesView from "./components/GameView";

interface Props {
  gameStore?: GameStore;
};
type TabName = "Teams"|"Games"|"Players"|"Game";
interface State {
  tab: TabName;
};

@inject("gameStore")
@observer
export default class AppMain extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this._changeTab = this._changeTab.bind(this);
    this.state = {tab: "Teams"};
  }

  async componentDidMount() {
  }

  render() {
    const renderTabContent = ()=>{
      switch (this.state.tab) {
        case "Teams":
          return <TeamsGrid/>;
        case "Players":
          return <PlayersGrid/>;
        case "Games":
          return <GamesGrid changeTab={this._changeTab}/>;
        case "Game":
          return <GamesView/>;
      };
    };

    return (
      <div className="App">
        <Container fluid>
          <Row>
            <Col>                
              <Nav tabs>
                <NavItem>
                  <NavLink className={this.state.tab === "Teams" ? "active" : ""} onClick={()=>this._changeTab("Teams")}>Teams</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={this.state.tab === "Players" ? "active" : ""} onClick={()=>this._changeTab("Players")}>Players</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={this.state.tab === "Games" ? "active" : ""} onClick={()=>this._changeTab("Games")}>Games</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={this.state.tab === "Game" ? "active" : ""} onClick={()=>this._changeTab("Game")}>Game</NavLink>
                </NavItem>
                {this.props.gameStore!.selectedGame && <NavItem>
                  <NavLink disabled><b>{this.props.gameStore!.selectedGame.title}</b></NavLink>
                </NavItem>}
              </Nav>
              {renderTabContent()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  _changeTab(tab: TabName) {
    this.setState({tab});
  }

}