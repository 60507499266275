import { Provider } from "mobx-react";
import * as React from "react";
import "./App.css";

import {Container, Row, Col, Nav, NavItem, NavLink, TabContent} from "reactstrap";
import {GameServiceLocalStorage} from "./service/GameService";
import GameStore from "./store/GameStore";
import AppMain from "./AppMain";

interface Props { };
interface State {
  tab: "Teams"|"Games"|"Players"|"Game";
};

export default class App extends React.Component<Props, State> {

  private gameStore: GameStore;

  constructor(props: Props) {
    super(props);
    this.gameStore = new GameStore(new GameServiceLocalStorage());
  }

  async componentDidMount() {
    await this.gameStore.init();
  }

  render() {
    return (
      <div className="App">
        <Provider gameStore={this.gameStore}>
          <AppMain/>
        </Provider>
      </div>
    );
  }

}