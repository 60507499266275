import { inject, observer } from "mobx-react";
import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button } from "reactstrap";
import GameStore from "../store/GameStore";
import {Player} from "../model/GameModel";

interface Props {
  gameStore?: GameStore;
  isOpen: boolean;
  player: Player;
  onSubmit: (player: Player)=>void;
  onCancel: ()=>void;
}

interface State {
  player?: Player;
}

@inject("gameStore")
@observer
export default class PlayerEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {player: props.player ? JSON.parse(JSON.stringify(props.player)): undefined};
  }

  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>
        Edit {this.props.player && this.props.player.name || "your player"}:
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input placeholder="What is the players name?" value={this.state.player?.name} onChange={(e)=>this._mergeChange({name: e.target.value})}/>
          </FormGroup>
          <Button color="secondary" outline onClick={()=>this.props.onCancel()}>Cancel</Button>
          <Button color="primary" disabled={this.state.player!.name === ""} onClick={()=>this._handleSubmit()}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _handleSubmit() {
    this.props.onSubmit(this.state.player!);
    this.setState({player: undefined});
  }

  _mergeChange(change: any) {
    const newPlayer = JSON.parse(JSON.stringify(this.state.player));
    for (const key of Object.getOwnPropertyNames(change)) {
      newPlayer[key] = change[key];
    }
    this.setState({player: newPlayer});
  }
}

