import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, Container, Row, Col, Button } from "reactstrap";
import {Game} from "../model/GameModel";
interface Props {
  game: Game;
  danger?: boolean;
  isOpen: boolean;
  title: string;
  message: string;
  onOk: ()=>void;
  onCancel: ()=>void;
}

export default class GameAction extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>
        {this.props.title}:
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <p>{this.props.message}</p>
              <Button color="secondary" outline onClick={()=>this.props.onCancel()}>Cancel</Button>
              <Button color={this.props.danger ? "danger" : "primary"} className="pull-right" onClick={()=>this.props.onOk()}>OK</Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>;
  }

}

