import { inject, observer } from "mobx-react";
import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button } from "reactstrap";
import GameStore from "../store/GameStore";
import {Game} from "../model/GameModel";

interface Props {
  gameStore?: GameStore;
  isOpen: boolean;
  game: Game;
  onSubmit: (game: Game)=>void;
  onCancel: ()=>void;
}

interface State {
  game?: Game;
}

@inject("gameStore")
@observer
export default class GameEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const newGame = JSON.parse(JSON.stringify(props.game)) as Game;
    if (!newGame.title) {
      newGame.title = new Date().toDateString().substring(4) + ": " + props.gameStore?.selectedTeamName;
    }
    this.state = {game: newGame};
  }

  render() {

    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>Set the game options</ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input placeholder="What is the game name?" value={this.state.game!.title} onChange={(e)=>this._mergeChange({title: e.target.value})}/>
          </FormGroup>
          <FormGroup>
            <Label>Number of players on field</Label>
            <Input type="number" placeholder="How many on-field? (inc keeper)" value={this.state.game!.expectOnFieldPlayerCount + ""} onChange={(e)=>this._mergeChange({expectOnFieldPlayers: parseInt(e.target.value)})}/>
          </FormGroup>
          <FormGroup>
            <Label>Substitute interval (minutes)</Label>
            <Input type="number" placeholder="How many minutes per play?" value={this.state.game!.subIntervalMins+ ""} onChange={(e)=>this._mergeChange({subIntervalMins: parseInt(e.target.value)})}/>
          </FormGroup>
          <Button color="secondary" outline onClick={()=>this.props.onCancel()}>Cancel</Button>
          <Button color="primary" disabled={this.state.game!.title === ""} onClick={()=>this._handleSubmit()}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _mergeChange(change: any) {
    const newGame = JSON.parse(JSON.stringify(this.state.game));
    for (const key of Object.getOwnPropertyNames(change)) {
      newGame[key] = change[key];
    }
    this.setState({game: newGame});
  }

  _handleSubmit() {
    this.props.onSubmit(this.state.game!);
    this.setState({game: undefined});
  }

}

