import { inject, observer } from "mobx-react";
import * as React from "react";
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button } from "reactstrap";
import GameStore from "../store/GameStore";
import {Team} from "../model/GameModel";

interface Props {
  gameStore?: GameStore;
  team?: Team;
  isOpen: boolean;
  onSubmit: (team: Team)=>void;
  onCancel: ()=>void;
}

interface State {
  team?: Team;
}

@inject("gameStore")
@observer
export default class TeamEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {team: props.team ? JSON.parse(JSON.stringify(props.team)): undefined};
  }
  render() {
    return <Modal isOpen={this.props.isOpen}>
      <ModalHeader>
        Edit {this.props.team && this.props.team.name || "your team"}:
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e)=>e.preventDefault()}>
          <FormGroup>
            <Label>Name</Label>
            <Input placeholder="What is the team name?" value={this.state.team?.name} onChange={(e)=>this._mergeChange({name: e.target.value})}/>
          </FormGroup>
          <Button color="secondary" outline onClick={()=>this.props.onCancel()} disabled={this.props.gameStore?.teams.length===0}>Cancel</Button>
          <Button color="primary" disabled={!this.state.team?.name} onClick={()=>this._handleSubmit()}>Submit</Button>
        </Form>
      </ModalBody>
    </Modal>;
  }

  _handleSubmit() {
    this.props.onSubmit(this.state.team!);
    this.setState({team: undefined});
  }

  _mergeChange(change: any) {
    const newTeam = JSON.parse(JSON.stringify(this.state.team));
    for (const key of Object.getOwnPropertyNames(change)) {
      newTeam[key] = change[key];
    }
    this.setState({team: newTeam});
  }

}

