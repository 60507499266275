import { inject, observer } from "mobx-react";
import * as React from "react";
import { Table, Button, UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem, Modal  } from "reactstrap";
import GameStore from "../store/GameStore";
import { Team } from "../model/GameModel";
import TeamAdd from "../components/TeamAdd";
import TeamEdit from "../components/TeamEdit";
import GenericDelete from "../components/GenericDelete";

interface Props {
  gameStore?: GameStore;
}

interface State {
  modal: ""|"TeamAdd"|"TeamEdit"|"TeamDelete";
  eventTeam?: Team;
};

@inject("gameStore")
@observer
export default class TeamsGrid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {modal: ""};
  }

  render() {
    let rowCount = 1;

    return <>
      {this.state.modal==="TeamAdd" && <TeamAdd isOpen={true} onCancel={()=>this._handleModalCancel()} onSubmit={(name)=>this._handleTeamAdd(name)}/>}
      {this.state.modal==="TeamEdit" && <TeamEdit isOpen={true} onCancel={()=>this._handleModalCancel()} team={this.state.eventTeam} onSubmit={(team)=>this._handleTeamEdit(team)}/>}
      <GenericDelete
        isOpen={this.state.modal==="TeamDelete"}
        onCancel={()=>this._handleModalCancel()}
        onSubmit={(id)=>this._handleTeamDelete(id)}
        itemId={this.state.eventTeam?.teamId || ""}
        itemType="Team"
        itemName={this.state.eventTeam?.name ||"" }
      />
      <Table>
        <thead>
          <tr>
            <th>
              #
            </th>
            <th>
              Name
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.gameStore?.teams.map(t=> {
            return <tr key={`team-${t.teamId}`} className={this.props.gameStore?.selectedTeamId === t.teamId ? "table-primary": ""}>
              <th scope="row" title={t.teamId}>{rowCount++}</th>
              <td>{t.name}</td>
              <td>
                <UncontrolledDropdown group>
                  <Button outline color="primary" size="sm" onClick={()=> this.setState({eventTeam: t, modal: "TeamEdit"})}>Edit</Button>
                  <DropdownToggle
                    caret
                    color="primary"
                    size="sm"
                  />
                  <DropdownMenu>
                    <DropdownItem onClick={()=> this._handleTeamSelect(t.teamId)} size="sm">
                      Select
                    </DropdownItem>
                    <DropdownItem onClick={()=> this.setState({eventTeam: t, modal: "TeamDelete"})} size="sm">
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown></td>
            </tr>;
          })}
        </tbody>
      </Table>
      <Button color="primary" onClick={()=>this.setState({modal: "TeamAdd"})}>New Team</Button>
    </>;
  }

  async _handleTeamSelect(id?: string) {
    await this.props.gameStore?.selectTeam(id);
  }

  _handleModalCancel() {
    this.setState({modal: ""});
  }

  async _handleTeamAdd(name: string) {
    this.setState({modal: "", eventTeam: undefined});
    await this.props.gameStore?.upsertTeam({name});
  }
  async _handleTeamEdit(team: Team) {
    this.setState({modal: "", eventTeam: undefined});
    await this.props.gameStore?.upsertTeam(team);
  }

  async _handleTeamDelete(id: string) {
    this.setState({modal: "", eventTeam: undefined});
    await this.props.gameStore?.deleteTeam(id);
  }

}

